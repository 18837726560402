 .planning-button {
  margin: 6px;
}

.buttons-row {
  display: flex;
  justify-content: flex-end;
}

.planning {
  padding-bottom: 72px;
}

.duplicate-button {
  margin: 0 0 0 12px;
}

.duplicate-button:focus {
  box-shadow: none;
}

.send-button {
  margin: 0 0 0 12px;
  background: #9eecfa;
  border: 1px solid #9eecfa;
}

.send-button:hover {
  background: #86d1df;
  border: 1px solid #86d1df;
}

.send-button:focus {
  box-shadow: none;
}

.absent-button {
  border: None;
  background: tomato;
  font-weight: bold;
}

.absent-button:hover {
  background: tomato;
}

.fermeture {
  background-color: #e6e6e6;
  pointer-events: none;
  cursor: default;
}

.fc-time span {
  font-weight: bold;
}

.fc-unthemed th, .fc-unthemed td, .fc-unthemed thead, .fc-unthemed tbody, .fc-unthemed .fc-divider, .fc-unthemed .fc-row, .fc-unthemed .fc-content, .fc-unthemed .fc-popover, .fc-unthemed .fc-list-view, .fc-unthemed .fc-list-heading td {
  border-color: #9e9e9e;
}
