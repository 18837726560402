.status p {
  font-size: 18pt;
}

.status img {
  width: 24px;
  height: 16px;
}

.status .dropdownStatus{
  padding:0px;
  background-color: transparent;
  border: transparent;
  font-size: 18pt;
}

.status .dropdownStatus:focus{
  background-color: transparent;
  border: transparent;
  box-shadow: transparent;
}

.header {
  position: relative;
}

.header > div {
  position: absolute;
  bottom: 33%;
}

.emailIcon {
  cursor: pointer;
}