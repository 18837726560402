.eleves-list {
  position: absolute;
  z-index: 1;
  background: white;
  border: 1px solid #e4e7ea;
  list-style: none;
  padding: 12px;
  width: 200px;
  border-radius: 6px;
}

.eleves-list > li {
  cursor: pointer;
}

.eleves-list > li:hover {
  text-decoration: underline;
}

.active-eleve {
  text-decoration: underline;
}