.message {
  margin: 12px 0;
  background-color: #ebf6ff;
  padding: 12px 6px;
}

.messageDate {
  margin-right: 12px;
}

.delete-button {
  border: None;
  background: none;
  color: tomato;
  font-weight: bold;
  margin-right: 6px;
}

.delete-button:hover {
  background: none;
}