// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './coreui/style.scss';

// full calendar grid item height
.fc-time-grid .fc-slats td {
  height: 2.5em !important;
}

.card-header {
  background-image: url('pageTitle.svg');
  font-size:18px;
}

.table td {
  vertical-align: middle;
}

.full-width {
  width: 100%;
}

body {
  background-color: #ffffff;
}

.addEleve {
  margin: 0 6px;
  border-radius: 6px;
  color: #ff795c;
  background-color: #ffffff;
  font-weight: bold;
}

.pageTitle {
  font-weight: bold;
  font-size: 50pt;
  padding-left:25px;
  padding-top:10px;
  padding-bottom:10px;
  // background-image: linear-gradient(to right, #9ff8e7, #9fefbc);
  background-image: url('pageTitle.svg');
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 25px;
}

.number {
  margin-top: 25px;
}

.number p {
  font-size:21pt;
  font-weight: bold;
  color: #5a5a5a;
}

.number h5 {
  font-size:43pt;
  font-weight: bold;
  color: #ff795c;
}

.number.black h5 {
  font-size:43pt;
  font-weight: bold;
  color: #595959;
}

.number.blue h5 {
  font-size:43pt;
  font-weight: bold;
  color: rgb(38, 72, 182);
}

.primaryButton {
  background-image: linear-gradient(to right, #40ced3, #2fc7bf);
  font-weight: bold;
  border: None;
  padding-left: 20px;
  padding-right: 20px;
}

.deleteButton {
  border: None;
  background: none;
  color: red;
  font-weight: bold;
  &:hover {
    background: none;
  }
}

.colorButton {
  background-image: linear-gradient(to right, #ff795c, #ff5c5c);
  font-weight: bold;
  border: None;
  padding-left: 20px;
  padding-right: 20px;
  color: #fff;
  &:hover {
    background-image: linear-gradient(#ff795c, #ff5c5c);
    color: #fff;
  }
}

.otoschoolTable {
  margin-top: 15px;
}

.otoschoolTable thead {
  background-color: #ebf6ff;
}

.otoschoolTable.clickable .table-striped tbody {
  cursor: pointer;
}

.otoschoolTable .table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #fff;
}

.otoschoolTable .table-striped tbody tr:hover {
  background-color: #dbe4e9;
}

.bordereau-eleve {
  display: flex;
  flex-wrap: wrap;
}

.bordereau-eleve-name {
  background-color: #ebf6ff;
  margin: 3px 6px 3px 0;
  padding: 2px 6px;
  border-radius: 3px;
}

.profilEleve {
  margin-top:50px;
}

.menuEleve {
  background-color: #ebf6ff;
}

.menuEleveItem {
  background-color: #ebf6ff;
  color: #595959;
  padding: 10px;
  border: None;
  border-radius: 0px;
}

.menuEleveItem:hover {
  background-image: linear-gradient(to left, #9ff8e7, #9fefbc);
}

.menuEleveItem.active {
  color: #595959;
  background-image: linear-gradient(to left, #9ff8e7, #9fefbc);
}

.menuEleve h4 {
  font-weight: bold;
  color: #595959;
  padding: 10px;
}

.menuEleve .menuEleveItem img {
  height: 13px;
  width: 13px;
  filter: invert(35%) sepia(0%) saturate(0%) hue-rotate(96deg) brightness(94%) contrast(88%);
}

.menuEleve hr {
  margin-top:0.25rem;
  margin-bottom: 0.25rem;
  width: 90%;
  border-top: 1px solid #A0A2A3;
}

.otoschoolNavTabs {
  background-color: #ebf6ff;
  border-bottom: 1px solid #ebf6ff;
  padding: 2px;
}

.otoschoolTab {
  background-color: #fff;
}

.otoschoolTab .nav-link.active{
  background-image: linear-gradient(to left, #9ff8e7, #9fefbc);
}

.otoschoolTab .nav-link {
  border: None;
  border-radius: 0px;
}

.otoschoolTabContent {
  border-radius: 0px;
  border: None;
}

.otoschoolCard {
  padding: 1px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.condition-clickable {
  cursor: pointer;
}

.menu {}
.menuItem {}

.menu > .menuItem > .menuLink {
  color: #000000;
  font-size: 17pt;
  font-weight: bold;
  text-align: center;
}

.menu > .menuItem > .menuLink .nav-link {
  color: #000000;
  font-size: 17pt;
  font-weight: bold;
  text-align: center;
}

.menu > .menuItem > .menuLink img {
  display: block;
  width:46px;
  height:46px;
  filter: invert(12%) sepia(93%) saturate(6270%) hue-rotate(235deg) brightness(88%) contrast(120%);
}
.menu > .menuItem > .menuLink:hover img {
  filter: invert(25%) sepia(65%) saturate(4338%) hue-rotate(308deg) brightness(98%) contrast(109%);
}

.menu > .menuItem > .menuLink.active img {
  filter: invert(25%) sepia(65%) saturate(4338%) hue-rotate(308deg) brightness(98%) contrast(109%);
}

.menu > .menuItem > .menuLink:active img {
  filter: invert(25%) sepia(65%) saturate(4338%) hue-rotate(308deg) brightness(98%) contrast(109%);
}

.document-download a{
  color: #000;
  text-align: center;
}

.document-logo {
  margin-bottom: 5px;
}

.searchbar > .form-control {
  border-radius: 0px;
  border: 1px solid #ccc;
}

.searchAdd .addEleve {
  width: 100%;
  height: 100%;
}

.addEleve {
  border: 1px solid #ccc;
}

.addEleve:hover {
  background-color: #ff795c;
  color: #fff;
  border: 1px solid #ccc;
}

.subTitle {
  background-color: #ebf6ff;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

div.subTitle {
  margin-bottom:25px;
  font-size: 1.2em;
}

select.form-control.select-article[multiple] {
  height: 10em;
}

select.form-control.select-formule[multiple] {
  height: 15em;
}

.vehicule {
  margin-top: 25px;
  padding: 15px;
}

.vehicule .col {
  margin-top: 15px;
}

// Override day picker class
.col .DayPickerInput {
  display: inline;
}

// Override paginator zindex. When datepicker displayed, pagenumber was on top of it
.page-item.active .page-link {
  z-index: 0;
}

.form-control {
  border-color: #ccc;
}

.note {
  background-color: #23475B;
  // background-color: #000;
}

.btn-secondary {
  background-color: #fff;
  border-color: #ccc;
}

.btn-secondary:hover {
  background-color: #dbe4e9;
}

.page-link {
  border-color: #ccc;
  color: #000;
}

.page-item.active .page-link {
  background-color: #ebf6ff;
  border-color: #ccc;
  color: #000;
}

.page-item .page-link:hover {
  background-color: #ebf6ff;
  color: #000;
}

.dropdown-item a {
  color: #000;
}

.otoschool-badge-item {
    position:relative;
    padding-top:20px;
    display:inline-block;
}
.otoschool-badge{
    position: absolute;
    right:-10px;
    top:10px;
    background:#ff16a5;
    text-align: center;
    border-radius: 30px 30px 30px 30px;
    color:white;
    padding:5px;
}
.menuEleve {
  padding-top: 15px;
}

.menuEleve .profilPicture {
  width: 250px;
  height: 250px;
  display: block;
  text-align: center;
  margin: auto;
}

.profilPicture img {
  max-width: 100%;
  max-height: 100%;
  cursor: pointer;
}

.smallProfilPicture {
  width: 55px;
  height: 55px;
  display: block;
  text-align: center;
  margin: auto;
}

.smallProfilPicture .profilPictureThumbnail {
  max-width: 100%;
  max-height: 100%;
  cursor: pointer;
}


button.profilPicture i {
  font-size: 4rem;
}

button.profilPicture p {
  font-size: 1.2rem;
}

.menuProfilPicture {
  width: 250px;
  display: block;
  text-align: center;
  margin: auto;
}

.noStyleButton {
  background-color: transparent;
  border: 1px solid #A0A2A3;
  display: block;
  margin: auto;
}

// div {
//   border: 1px solid black;
// }
