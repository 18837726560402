.print-button {
  background: none;
  border: none;
}

.print-full {
  color: green;
}

.print-data {
  color: orange;
}