.actionbar {
  margin-bottom: 15px;
}

.actionbar input {
  margin-bottom: 15px;
}
.actionbar button {
  margin-right: 25px;
}

.table tbody tr:hover {
  background-color: #FFEFD5;
}

.actions button{
  margin-right: 5px;
}
