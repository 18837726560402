@media (min-width: 34em) {
    .card-columns {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }
}

h5 {
  margin-bottom:15px;
}

button[type="submit"] {
  margin-bottom:15px;
}
