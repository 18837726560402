.innerCard {
  padding-bottom: 15px;
  margin-top: 25px;
}

.innerCard > div {
  padding-left: 15px;
  padding-right: 15px;
}

.hell input {
  margin-bottom: 15px;
}

.hell button {
  margin-bottom: 15px;
}

.messages {
  margin-top: 24px;
}

/* Mimic button type="submit" for eleve permis form */
.submit {
  margin-bottom: 15px;
}
